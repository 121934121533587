import { Component } from 'react'

import './index.scss'

interface BallProps {
  left?: string,
  right?: string,
  top?: string,
  bottom?: string,
  delay?: number,
  scale?: number
}

interface BallState {}

class Ball extends Component<BallProps, BallState> {

  public static defaultProps = {
    left: 'auto',
    right: 'auto',
    top: 'auto',
    bottom: 'auto',
    delay: 0,
    scale: 1
  }

  public render () {
    let { left, right, top, bottom, delay, scale } = this.props
    return (
      <div className='page-ball' style={{ left, right, top, bottom, transform: `scale(${scale})` }}>
        <div className='ball-content' style={{ animationDelay: `${delay}s` }}></div>
        <div className='ball-shadow flex-align-justify'>
          <div className='shadow-content' style={{ animationDelay: `${delay}s` }}></div>
        </div>
      </div>
    )
  }
}

export default Ball
