import ImageWebBackground from '../asserts/home/background.png'
import ImageMobileBackground from '../asserts/home/background-mobile.png'
import ImagePage11 from '../asserts/home/page/page1-1.png'
import ImagePage12 from '../asserts/home/page/page1-2.png'
import ImagePage2 from '../asserts/home/page/page2.png'
import ImagePage31 from '../asserts/home/page/page3-1.png'
import ImagePage32 from '../asserts/home/page/page3-2.png'
import ImagePage41 from '../asserts/home/page/page4-1.png'
import ImagePage42 from '../asserts/home/page/page4-2.png'
import ImageAbout1 from '../asserts/home/about/about1.png'
import ImageAbout2 from '../asserts/home/about/about2.png'
import ImageAbout3 from '../asserts/home/about/about3.png'
import ImageSolution1 from '../asserts/home/solution/solution1.png'
import ImageSolution2 from '../asserts/home/solution/solution2.png'
import ImageSolution3 from '../asserts/home/solution/solution3.png'
import ImageSolution4 from '../asserts/home/solution/solution4.png'
import ImageSolution5 from '../asserts/home/solution/solution5.png'
import ImageSolution6 from '../asserts/home/solution/solution6.png'
import ImageFeature1 from '../asserts/home/feature/feature1.png'
import ImageFeature2 from '../asserts/home/feature/feature2.png'
import ImageFeature3 from '../asserts/home/feature/feature3.png'
import ImageFeature4 from '../asserts/home/feature/feature4.png'
import ImageFeature5 from '../asserts/home/feature/feature5.png'
import ImageFeature6 from '../asserts/home/feature/feature6.png'
import ImageFeature7 from '../asserts/home/feature/feature7.png'
import ImageFeature8 from '../asserts/home/feature/feature8.png'
import ImageFeature9 from '../asserts/home/feature/feature9.png'
import ImageReputation1 from '../asserts/home/reputation/boss.png'
import ImageReputation2 from '../asserts/home/reputation/manager.png'
import ImageReputation3 from '../asserts/home/reputation/staff.png'
import ImageReputation4 from '../asserts/home/reputation/member.png'

export const HOMECONFIG = {
  menuList: [
    { label: '主页', jump: 'home' },
    { label: '关于我们', jump: 'about' },
    { label: '解决方案', jump: 'solution' },
    { label: '特色功能', jump: 'feature' },
    { label: '行业口碑', jump: 'reputation' },
    { label: '联系我们', jump: 'contact' },
  ],
  homeTab: {
    title: '会员分析、全民营销、轻松卖货',
    introduction: ['金斗云，最懂珠宝门店的新一代智慧门店管理系统。', '全新的SaaS+AI模式，旨在协助门店轻松卖货提升业绩。'],
    sloganList: [
      '沉淀会员流量池',
      '快速营销获客',
      '功能持续更新',
      '操作简单易上手'
    ],
    tag: {
      list: ['不包装概念', '不营造焦虑', '不夸大效果'],
      title: '稳定运行、持续升级'
    },
    tip: '立足当下最合适，面向未来最领先',
    button: {
      link: 'https://jinshuju.net/f/TP3rhr',
      text: '申请试用'
    },
    webBackground: ImageWebBackground,
    mobileBackground: ImageMobileBackground,
    swiperBackground: {
      page11: ImagePage11,
      page12: ImagePage12,
      page2: ImagePage2,
      page31: ImagePage31,
      page32: ImagePage32,
      page41: ImagePage41,
      page42: ImagePage42
    }
  },
  aboutTab: {
    title: '关于我们',
    list: [
      {
        image: ImageAbout1,
        title: '创始团队',
        introduction: '金斗云创始团队都是10年以上的珠宝门店老板，有丰富的门店运营管理经验，最懂珠宝门店需要。'
      },
      {
        image: ImageAbout2,
        title: '研发团队',
        introduction: '金斗云研发团队服务珠宝行业多年，熟悉门店场景，技术累积扎实，专注珠宝行业的技术团队。'
      },
      {
        image: ImageAbout3,
        title: '金斗云系统',
        introduction: '金斗云系统与全国多地的优质客户建立了长期合作，将当前优秀的经验和思路不断融入系统。'
      }
    ]
  },
  solutionTab: {
    title: '解决方案',
    discription: '金斗云提供当前阶段门店最需要的系统，通过重新设计会员、营销、员工、数据、ERP五大模块，将门店经营中的所有场景串联起来，解决一直存在的系统不能持续有效促进卖货的问题，提升业绩、规范流程、简化操作',
    list: [
      {
        image: ImageSolution1,
        title: '会员管理',
        introduction: '手机+微信全渠道打通，沉淀会员数据，精准获客持续卖货'
      },
      {
        image: ImageSolution2,
        title: '营销引流',
        introduction: '深度整合微信关系链，爆款工具百变玩法，业绩圈粉两不误'
      },
      {
        image: ImageSolution3,
        title: '员工管理',
        introduction: '全员参与，导购行为数据量化，轻松供应对多场景管理'
      },
      {
        image: ImageSolution4,
        title: '数据服务',
        introduction: '个性化的数据统计产品，进销存、实时业绩一目了然'
      },
      {
        image: ImageSolution5,
        title: '移动端',
        introduction: '微信小程序系统助手，老板、管理层、员工轻松接入'
      },
      {
        image: ImageSolution6,
        title: 'ERP',
        introduction: '货品数据全流程追踪，销量开单精细化记录'
      }
    ]
  },
  featureTab: {
    title: '特色功能',
    discription: '深度围绕会员及营销，一切只为更好的卖货',
    list: [
      {
        image: ImageFeature1,
        title: '会员分析',
        introduction: '多维度定向营销、分层营销'
      },
      {
        image: ImageFeature2,
        title: '卡券派发',
        introduction: '开单、生日、活动...自动发券'
      },
      {
        image: ImageFeature3,
        title: '积分派发',
        introduction: '开单、生日、活动...自动送积分'
      },
      {
        image: ImageFeature4,
        title: '导购名片',
        introduction: '专属名片，轻松营销提升业绩'
      },
      {
        image: ImageFeature5,
        title: '在线商城',
        introduction: '打通线上线下，直播联动'
      },
      {
        image: ImageFeature6,
        title: '商家主页',
        introduction: '门店微主页，一站式会员导航'
      },
      {
        image: ImageFeature7,
        title: '客户维护',
        introduction: '自动提醒维护，精准获客'
      },
      {
        image: ImageFeature8,
        title: '业绩计算',
        introduction: '门店业绩实时查看'
      },
      {
        image: ImageFeature9,
        title: '数据灯塔',
        introduction: '一站式数据看板'
      }
    ]
  },
  reputationTab: {
    title: '行业口碑',
    list: [
      {
        image: ImageReputation1,
        title: '老板',
        introduction: '“金斗云，可以随时随地关注销售业绩、实时和历史库存；灵活的权限控制及员工分组方便管理，关键操作预警，能及时处理潜在问题，不管是放权行的老板还是一手抓的老板，都能做到心中有数，把控全局。”'
      },
      {
        image: ImageReputation2,
        title: '管理层',
        introduction: '“金斗云，帮助规范门店流程，随时查看每个员工对门店对贡献度，优化销售目标，设定激励任务，多维度的会员分析和定向营销，提效率、控成本，管人、管货、管业季，面面俱到，游刃有余。”'
      },
      {
        image: ImageReputation3,
        title: '员工',
        introduction: '“金斗云，卖货好帮手，可以查看自己的销售排名、实时业绩完成进度、营销活动推广成单数据，添加顾客接待记录，待回访会员自动提醒，门店新任务自动提醒，目标明确，科学提升业绩。”'
      },
      {
        image: ImageReputation4,
        title: '会员',
        introduction: '“金斗云，依托门店公众号的一站式会员服务，积分卡券、专属顾问、电子质保单管理，订阅门店最新优惠活动，随时随地与门店互动，第一时间享受门店提供的各项会员福利。”'
      }
    ]
  },
  contactTab: {
    title: '联系我们',
    button: '关注公众号，申请试用'
  }
}