import { Component } from 'react'

import './index.scss'

import ImageChrome from '../../asserts/browser/chrome.png'
import ImageFirefox from '../../asserts/browser/firefox.png'
import ImageSafari from '../../asserts/browser/safari.png'
import ImageEdge from '../../asserts/browser/edge.png'

class Tip extends Component<{}, {}> {
  public render () {
    return (
      <div className='app-page page-tip'>
        <div className='tip-container'>
          <div className='tip-title'>您当前浏览器的版本过低</div>
          <div className='tip-text'>为了获得更友好的浏览体验，请升级到最新版</div>
          <div className='tip-text'>或者使用以下主流浏览器：</div>
          <div className='browser-list'>
            <div className='browser-item'>
              <img className='browser-image' src={ImageChrome} alt='Chrome'/>
              <div className='browser-title'>Chrome</div>
            </div>
            <div className='browser-item'>
              <img className='browser-image' src={ImageFirefox} alt='Firefox'/>
              <div className='browser-title'>Firefox</div>
            </div>
            <div className='browser-item'>
              <img className='browser-image' src={ImageSafari} alt='Safari'/>
              <div className='browser-title'>Safari</div>
            </div>
            <div className='browser-item'>
              <img className='browser-image' src={ImageEdge} alt='Microsoft Edge'/>
              <div className='browser-title'>Microsoft Edge</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Tip