import { Component, RefObject, createRef } from 'react'

import './index.scss'

import Header from '../../components/Header'
import Ball from '../../components/Ball'
import Background from '../../components/Background'

import { APPCONFIG } from '../../configs/common'
import { HOMECONFIG } from '../../configs/page'

import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Autoplay, Pagination])

const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) => obj[key]

interface scrollData {
  home: number,
  about: number,
  solution: number,
  feature: number,
  reputation: number,
  contact: number
}

interface HomeProps {}

interface HomeState {
  activeTab: number,
  isChangeTab: boolean,
  scrollTop: number,
  scrollList: scrollData,
  sloganIndex: number
}

class Home extends Component<HomeProps, HomeState> {

  public state = {
    activeTab: 0,
    isChangeTab: false,
    scrollTop: 0,
    scrollList: {
      home: 0,
      about: 0,
      solution: 0,
      feature: 0,
      reputation: 0,
      contact: 0
    },
    sloganIndex: 0
  }

  private scrollContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private headerContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private homeContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private aboutContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private solutionContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private featureContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private reputationContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private contactContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()

  componentDidMount () {
    this.setState({
      scrollList: {
        home: this.homeContainer.current?.offsetTop || 0,
        about: this.aboutContainer.current?.offsetTop || 0,
        solution: this.solutionContainer.current?.offsetTop || 0,
        feature: this.featureContainer.current?.offsetTop || 0,
        reputation: this.reputationContainer.current?.offsetTop || 0,
        contact: this.contactContainer.current?.offsetTop || 0
      }
    })
  }

  handleChangeTab (jump: any, index: number) {
    let scrollTop: number = getKeyValue<keyof scrollData, scrollData>(jump)(this.state.scrollList)
    let appHeaderHeight: number = this.headerContainer.current?.offsetHeight || 0
    this.setState({ activeTab: index, isChangeTab: true })
    this.scrollSmoothTo(scrollTop - appHeaderHeight)
  }

  handleChangeBackground (index: number) {
    this.setState({ sloganIndex: index })
  }

  scrollSmoothTo (position: number) {
    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function (callback) {
        return setTimeout(callback, 17)
      }
    }
    let that = this
    // 当前滚动高度
    let scrollTop: number = this.scrollContainer.current?.scrollTop || 0
    // 滚动step方法
    let step = function () {
      // 距离目标滚动距离
      let distance = position - scrollTop
      // 目标滚动位置
      scrollTop = scrollTop + distance / 5
      if (Math.abs(distance) < 1) {
        that.setState({ isChangeTab: false })
        that.scrollContainer.current?.scrollTo(0, position)
      } else {
        that.scrollContainer.current?.scrollTo(0, scrollTop)
        requestAnimationFrame(step)
      }
    }
    step()
  }

  handleScroll () {
    let arr: Array<number> = Object.values(this.state.scrollList)
    let scrollTop: number = this.scrollContainer.current?.scrollTop || 0
    let appHeaderHeight: number = this.headerContainer.current?.offsetHeight || 0
    let activeTab: number = 0
    for (let index = 0; index < arr.length; index++) {
      if (scrollTop + appHeaderHeight >= arr[index]) activeTab = index
    }
    if (this.state.isChangeTab) {
      this.setState({ scrollTop })
    } else {
      this.setState({ activeTab, scrollTop })
    }
  }

  public render () {
    let { activeTab, scrollTop, sloganIndex } = this.state
    const { homeTab, aboutTab, solutionTab, featureTab, reputationTab, contactTab } = HOMECONFIG
    const { appName, address, telphone, email, serviceWechat, serviceQrcode, officialWechat, officialQrcode, copyright, recordNumber } = APPCONFIG
    return (
      <div
        className='app-page page-index'
        ref={this.scrollContainer}
        onScrollCapture={this.handleScroll.bind(this)}
      >
        <Header
          className={`${scrollTop > 0 ? 'active' : ''}`}
          elementRef={this.headerContainer}
          active={activeTab}
          onChange={this.handleChangeTab.bind(this)}
        ></Header>
        <div className='tab-home' ref={this.homeContainer}>
          <Ball left='35%' top='10%'></Ball>
          <Ball left='45%' top='70%' delay={0.66} scale={0.7}></Ball>
          <Ball right='10%' top='30%' delay={0.33} scale={0.7}></Ball>
          <Background onChange={this.handleChangeBackground.bind(this)}></Background>
          <div className='page-container flex-column-justify'>
            <div className='home-title'>{homeTab.title}</div>
            <div className='home-subtitle flex-align'>
              <span className='subtitle-text'>{appName}</span>
              <div className='subtitle-slogan'>
                {
                  homeTab.sloganList.map((item, index) => {
                    return (
                      <div className={`slogan-content flex-align transition ${sloganIndex === index ? 'active' : ''}`} key={index}>{item}</div>
                    )
                  })
                }
              </div>
            </div>
            <div className='home-introduction flex flex-wrap'>
              {
                homeTab.introduction.map((item, index) => {
                  return (
                    <div key={index}>{item}</div>
                  )
                })
              }
            </div>
            <div className='home-tag flex-column'>
              <div className='tag-list flex'>
                {
                  homeTab.tag.list.map((item, index) => {
                    return (
                      <div className='tag-item flex-one flex-align-justify' key={index}>{item}</div>
                    )
                  })
                }
              </div>
              <div className='tab-info flex-align-justify'>{homeTab.tag.title}</div>
            </div>
            <div className='home-tip'>{homeTab.tip}</div>
            <a className='home-button flex' href={homeTab.button.link} target='_blank' rel='noreferrer'>
              <div className='flex-one flex-align-justify'>{homeTab.button.text}</div>
              <div className='button-arrow flex-align-justify'>
                <span className='iconfont icon-arrow-right'></span>
              </div>
            </a>
          </div>
        </div>
        <div className='tab-about' ref={this.aboutContainer}>
          <div className='page-container'>
            <div className='tab-title'>{aboutTab.title}</div>
            <div className='flex'>
              {
                aboutTab.list.map((item, index) => {
                  return (
                    <div className='about-block flex-one box' key={index}>
                      <div className='box-content flex-column-align-justify'>
                        <img className='block-image' src={item.image} alt={`${aboutTab.title}-${item.title}`} />
                        <div className='block-title'>{item.title}</div>
                        <div className='block-introduction'>{item.introduction}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className='tab-solution page-container' ref={this.solutionContainer}>
          <div className='tab-title'>{solutionTab.title}</div>
          <div className='tab-discription'>{solutionTab.discription}</div>
          <div className='flex flex-wrap'>
            {
              solutionTab.list.map((item, index) => {
                return (
                  <div className='solution-block flex-one box' key={index}>
                    <div className='box-content flex-column'>
                      <img className='block-image' src={item.image} alt={`${solutionTab.title}-${item.title}`} />
                      <div className='block-title flex-one'>{item.title}</div>
                      <div className='block-introduction'>{item.introduction}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='tab-feature page-container' ref={this.featureContainer}>
          <div className='tab-title'>{featureTab.title}</div>
          <div className='tab-discription'>{featureTab.discription}</div>
          <div className='feature-block-wrapper'>
            <div className='block-shadow shadow1'></div>
            <div className='block-shadow shadow2'></div>
            <div className='block-shadow shadow3'></div>
            <div className='block-shadow shadow4'></div>
            <div className='flex flex-wrap'>
              {
                featureTab.list.map((item, index) => {
                  return (
                    <div className='feature-block box flex-one' key={index}>
                      <div className='box-content flex-column-align'>
                        <img className='block-icon' src={item.image} alt={`${featureTab.title}-${item.title}`} />
                        <div className='block-title'>{item.title}</div>
                        <div className='block-introduction'>{item.introduction}</div>
                      </div>
                      <div className='block-border'></div>
                      <div className='block-border border1'>
                        <div className='block-angle'></div>
                      </div>
                      <div className='block-border border2'>
                        <div className='block-angle'></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className='tab-reputation page-container' ref={this.reputationContainer}>
          <Ball left='15%' top='20%'></Ball>
          <Ball right='8%' top='45%' delay={0.66} scale={0.7}></Ball>
          <div className='tab-title'>{reputationTab.title}</div>
          <div className='reputation-swiper'>
            <Swiper
              style={{ height: '100%' }}
              loop
              autoplay={{ disableOnInteraction: false }}
              pagination
            >
              {
                reputationTab.list.map((item, index) => {
                  return (
                    <SwiperSlide className='reputation-swiper-slide flex-column-align-justify' key={index}>
                      <img className='reputation-avatar' src={item.image} alt={`${reputationTab.title}-${item.title}`} />
                      <div className='reputation-title'>{item.title}</div>
                      <div className='reputation-introduction'>{item.introduction}</div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
        <div className='tab-contact' ref={this.contactContainer}>
          <div className='page-container flex-align'>
            <div className='flex-one'>
              <div className='contact-title'>{contactTab.title}</div>
              <a className='user-select' href={address.mapLink} target='_blank' rel='noreferrer'>{address.label}：{address.value}</a>
              <div className='user-select'>{telphone.label}：{telphone.value}</div>
              <div className='user-select'>{email.label}：{email.value}</div>
              <div className='user-select'>{serviceWechat.label}：{serviceWechat.value}</div>
              <div className='user-select'>{officialWechat.label}：{officialWechat.value}</div>
              <div className='contact-button flex-align-justify'>{contactTab.button}</div>
            </div>
            <div className='contact-qrcode flex-column-align'>
              <img className='qrcode-image' src={officialQrcode.image} alt={`${contactTab.title}-${officialQrcode.label}`} />
              <div>{officialQrcode.label}</div>
            </div>
            <div className='contact-qrcode flex-column-align'>
              <img className='qrcode-image' src={serviceQrcode.image} alt={`${contactTab.title}-${serviceQrcode.label}`} />
              <div>{serviceQrcode.label}</div>
            </div>
          </div>
        </div>
        <div className='index-footer flex-column-align'>
          <div className='footer-statement flex-align'>
            <div>金斗云支持</div>
            <div className='statement-tag flex-align-justify'>IPv6</div>
            <div>网络</div>
          </div>
          <div>{copyright}</div>
          <a href={recordNumber.link} target='_blank' rel='noreferrer'>{recordNumber.value}</a>
        </div>
      </div>
    )
  }
}

export default Home
