import { Component, RefObject, createRef } from 'react'

import './index.scss'

import Ball from '../../components/Ball'

import { APPCONFIG } from '../../configs/common'
import { HOMECONFIG } from '../../configs/page'
import { isMobileWechat } from '../../utils/func'

import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Autoplay, Pagination])

interface scrollData {
  home: number,
  about: number,
  solution: number,
  feature: number,
  reputation: number,
  contact: number
}

interface HomeProps {}

interface HomeState {
  activeTab: number,
  isChangeTab: boolean,
  scrollTop: number,
  scrollList: scrollData,
  sloganIndex: number
}

class Home extends Component<HomeProps, HomeState> {

  public state = {
    activeTab: 0,
    isChangeTab: false,
    scrollTop: 0,
    scrollList: {
      home: 0,
      about: 0,
      solution: 0,
      feature: 0,
      reputation: 0,
      contact: 0
    },
    sloganIndex: 0
  }

  private scrollContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private homeContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private aboutContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private solutionContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private featureContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private reputationContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private contactContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private officialQrcodeContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()
  private serviceQrcodeContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>()

  componentDidMount () {
    this.setState({
      scrollList: {
        home: this.homeContainer.current?.offsetTop || 0,
        about: this.aboutContainer.current?.offsetTop || 0,
        solution: this.solutionContainer.current?.offsetTop || 0,
        feature: this.featureContainer.current?.offsetTop || 0,
        reputation: this.reputationContainer.current?.offsetTop || 0,
        contact: this.contactContainer.current?.offsetTop || 0
      }
    })
  }

  handleScroll () {
    let scrollTop: number = this.scrollContainer.current?.scrollTop || 0
    this.setState({ scrollTop })
  }

  handleSlideChange (opts: any) {
    let activeIndex = opts.activeIndex - 1
    if (activeIndex === 4) {
      activeIndex = 0
    } else if (activeIndex === -1) {
      activeIndex = 3
    }
    this.setState({ sloganIndex: activeIndex })
  }

  handleTouchStartQrcode (type: string, event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (!isMobileWechat()) return
    if (type === '1') {
      this.serviceQrcodeContainer.current?.classList.add('active')
    } else {
      this.officialQrcodeContainer.current?.classList.add('active')
    }
    setTimeout(() => {
      this.handleTouchEndQrcode()
    }, 1000)
  }

  handleTouchEndQrcode () {
    this.officialQrcodeContainer.current?.classList.remove('active')
    this.serviceQrcodeContainer.current?.classList.remove('active')
  }

  public render () {
    let { scrollTop, sloganIndex } = this.state
    const { homeTab, aboutTab, solutionTab, featureTab, reputationTab, contactTab } = HOMECONFIG
    const { appTitle, appLogo, address, telphone, email, serviceWechat, serviceQrcode, officialWechat, officialQrcode, copyright, recordNumber } = APPCONFIG
    return (
      <div
        className='mobile-page page-index'
        ref={this.scrollContainer}
        onScrollCapture={this.handleScroll.bind(this)}
      >
        <div className={`mobile-header flex-align ${scrollTop > 0 ? 'active' : ''}`}>
          <div className='flex-one flex-align'>
            <img className='header-logo' src={appLogo} alt={appTitle} title={appTitle}/>
            <div className='header-title'>{appTitle}</div>
          </div>
          {/* <div className='header-menu flex-align-justify'>
            <span className='iconfont icon-menu'></span>
          </div> */}
        </div>
        <div className='tab-home flex-column' ref={this.homeContainer}>
          <div className='home-container flex-one flex-column-align' style={{ backgroundImage: `url(${homeTab.mobileBackground})` }}>
            <Ball right='0%' bottom='22%' scale={0.5}></Ball>
            <Ball left='3%' bottom='16%' delay={0.66} scale={0.4}></Ball>
            <div className='home-title'>{homeTab.title}</div>
            <div className='home-introduction'>
              {
                homeTab.introduction.map((item, index) => {
                  return (
                    <div key={index}>{item}</div>
                  )
                })
              }
            </div>
            <div className='home-swiper flex-one'>
              <Swiper
                style={{ height: '100%' }}
                pagination
                autoplay={{ disableOnInteraction: false }}
                loop
                onSlideChange={this.handleSlideChange.bind(this)}
              >
                <SwiperSlide className='home-swiper-slide flex-align-justify'>
                  <div className='swiper-window'>
                    <div className='window-container box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page11})` }}></div>
                    <div className='window-border'></div>
                    <div className='window-small box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page12})` }}></div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='home-swiper-slide flex-align-justify'>
                  <div className='swiper-window'>
                    <div className='window-container box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page2})` }}></div>
                    <div className='window-border'></div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='home-swiper-slide flex-align-justify'>
                  <div className='swiper-window'>
                    <div className='window-container box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page31})` }}></div>
                    <div className='window-border'></div>
                    <div className='window-mini box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page32})` }}></div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className='home-swiper-slide flex-align-justify'>
                  <div className='swiper-phone-wrapper flex-justify'>
                    <div className='swiper-phone'>
                      <div className='phone-container box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page41})` }}></div>
                      <div className='phone-border'></div>
                    </div>
                    <div className='swiper-phone'>
                      <div className='phone-container box' style={{ backgroundImage: `url(${homeTab.swiperBackground.page42})` }}></div>
                      <div className='phone-border'></div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className='home-slogan flex-justify'>
              {
                homeTab.sloganList.map((item, index) => {
                  return (
                    <div className={`slogan-content flex-align transition ${sloganIndex === index ? 'active' : ''}`} key={index}>{item}</div>
                  )
                })
              }
            </div>
          </div>
          <div className='home-footer flex-column-align'>
            <div className='home-tag flex-column'>
              <div className='tag-list flex'>
                {
                  homeTab.tag.list.map((item, index) => {
                    return (
                      <div className='tag-item flex-one flex-align-justify' key={index}>{item}</div>
                    )
                  })
                }
              </div>
              <div className='tab-info flex-align-justify'>{homeTab.tag.title}</div>
            </div>
            <div className='home-tip'>{homeTab.tip}</div>
            <a className='home-button flex-align-justify' href={homeTab.button.link} target='_blank' rel='noreferrer'>{homeTab.button.text}</a>
          </div>
        </div>
        <div className='tab-about' ref={this.aboutContainer}>
          <div className='tab-title'>{aboutTab.title}</div>
          <div>
            {
              aboutTab.list.map((item, index) => {
                return (
                  <div className='about-block flex-align' key={index}>
                    <img className='block-image' src={item.image} alt={`${aboutTab.title}-${item.title}`} />
                    <div className='flex-one'>
                      <div className='block-title'>{item.title}</div>
                      <div className='block-introduction'>{item.introduction}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='tab-solution' ref={this.solutionContainer}>
          <div className='tab-title'>{solutionTab.title}</div>
          <div className='tab-discription'>{solutionTab.discription}</div>
          <div className='flex flex-wrap'>
            {
              solutionTab.list.map((item, index) => {
                return (
                  <div className='solution-block flex-one box' key={index}>
                    <div className='box-content flex-column flex-justify-end'>
                      <img className='block-image' src={item.image} alt={`${solutionTab.title}-${item.title}`} />
                      <div className='block-title'>{item.title}</div>
                      <div className='block-introduction'>{item.introduction}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='tab-feature' ref={this.featureContainer}>
          <div className='tab-title'>{featureTab.title}</div>
          <div className='tab-discription'>{featureTab.discription}</div>
          <div className='feature-block-wrapper'>
            <div className='block-shadow shadow1'></div>
            <div className='block-shadow shadow2'></div>
            <div className='block-shadow shadow3'></div>
            <div className='block-shadow shadow4'></div>
            <div>
              {
                featureTab.list.map((item, index) => {
                  return (
                    <div className='feature-block box' key={index}>
                      <div className='box-content flex-column-align'>
                        <img className='block-icon' src={item.image} alt={`${featureTab.title}-${item.title}`} />
                        <div className='block-title'>{item.title}</div>
                        <div className='block-introduction'>{item.introduction}</div>
                      </div>
                      <div className='block-border'></div>
                      <div className='block-border border1'>
                        <div className='block-angle'></div>
                      </div>
                      <div className='block-border border2'>
                        <div className='block-angle'></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className='tab-reputation' ref={this.reputationContainer}>
          <Ball left='2%' top='5%' scale={0.5}></Ball>
          <Ball right='1%' bottom='46%' delay={0.66} scale={0.4}></Ball>
          <div className='tab-title'>{reputationTab.title}</div>
          <div className='reputation-swiper'>
            <Swiper
              style={{ height: '100%' }}
              loop
              autoplay={{ disableOnInteraction: false }}
              pagination
            >
              {
                reputationTab.list.map((item, index) => {
                  return (
                    <SwiperSlide className='reputation-swiper-slide flex-column-align-justify' key={index}>
                      <img className='reputation-avatar' src={item.image} alt={`${reputationTab.title}-${item.title}`} />
                      <div className='reputation-title'>{item.title}</div>
                      <div className='reputation-introduction'>{item.introduction}</div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
        <div className='tab-contact flex-column-align' ref={this.contactContainer}>
          <Ball left='6%' top='18%' delay={0.33} scale={0.4}></Ball>
          <div className='tab-title'>{contactTab.title}</div>
          <a className='user-select' href={address.mapLink} target='_blank' rel='noreferrer'>{address.label}：{address.value}</a>
          <a className='user-select' href={`tel:${telphone.value}`}>{telphone.label}：{telphone.value}</a>
          <div className='user-select'>{email.label}：{email.value}</div>
          <div className='user-select'>{serviceWechat.label}：{serviceWechat.value}</div>
          <div className='user-select'>{officialWechat.label}：{officialWechat.value}</div>
          <div className='contact-button flex-align-justify'>{contactTab.button}</div>
          <div className='contact-qrcode-wrapper flex'>
            <div className='contact-qrcode flex-column-align'>
              <div
                className='qrcode-image-wrapper'
                ref={this.officialQrcodeContainer}
                onTouchStart={this.handleTouchStartQrcode.bind(this, '1')}
                onTouchEnd={this.handleTouchEndQrcode.bind(this)}
              >
                <img className='qrcode-image qrcode-active' src={serviceQrcode.image} alt={`${contactTab.title}-${serviceQrcode.label}`} />
                <img className='qrcode-image' src={officialQrcode.image} alt={`${contactTab.title}-${officialQrcode.label}`} />
              </div>
              <div>{officialQrcode.label}</div>
            </div>
            <div className='flex-one flex flex-justify-end'>
              <div className='contact-qrcode flex-column-align'>
                <div
                  className='qrcode-image-wrapper'
                  ref={this.serviceQrcodeContainer}
                  onTouchStart={this.handleTouchStartQrcode.bind(this, '2')}
                  onTouchEnd={this.handleTouchEndQrcode.bind(this)}
                >
                  <img className='qrcode-image qrcode-active' src={officialQrcode.image} alt={`${contactTab.title}-${officialQrcode.label}`} />
                  <img className='qrcode-image' src={serviceQrcode.image} alt={`${contactTab.title}-${serviceQrcode.label}`} />
                </div>
                <div>{serviceQrcode.label}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='index-footer flex-column-align'>
          <div className='footer-statement flex-align'>
            <div>金斗云支持</div>
            <div className='statement-tag flex-align-justify'>IPv6</div>
            <div>网络</div>
          </div>
          <div>{copyright}</div>
          <a href={recordNumber.link} target='_blank' rel='noreferrer'>{recordNumber.value}</a>
        </div>
      </div>
    )
  }
}

export default Home
