import { Component } from 'react'
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom'

import './styles/main.scss'
import './iconfonts/iconfont.css'
import 'swiper/swiper.scss'

import Home from './pages/Home'
import MobileHome from './mobile/Home'
import Tip from './pages/Tip'
import NotFound from './pages/NotFound'
import Screen from './pages/Screen'
import ScreenDev from './pages/Screen/dev'

import { isLowVersionBrowser } from './utils/func'

interface AppProps extends RouteComponentProps {}

interface AppState {}

class App extends Component<AppProps, AppState> {

  componentDidMount () {
    if (isLowVersionBrowser()) {
      this.props.history.replace('/tip')
      return
    }
    this.changePage(document.body.clientWidth, document.body.clientHeight)

    let that = this
    window.onresize = function (e: any) {
      that.changePage(e.currentTarget.innerWidth || document.body.clientWidth, e.currentTarget.innerHeight || document.body.clientHeight)
    }
  }

  changePage (width: number, height: number) {
    const pathname = this.props.history.location.pathname
    if (pathname.indexOf('screen') >= 0) return
    if ( height / width >= 1.3 && pathname !== '/m') {
      this.props.history.replace('/m')
    } else if (height / width < 1.3 && pathname !== '/') {
      this.props.history.replace('/')
    }
  }

  public render () {
    return (
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/m' component={MobileHome}></Route>
        <Route exact path='/tip' component={Tip}></Route>
        <Route exact path='/screen' component={Screen}></Route>
        <Route exact path='/dev-screen' component={ScreenDev}></Route>
        <Route component={NotFound}></Route>
      </Switch>
    )
  }
}

export default withRouter(App)
