import { Component, RefObject } from 'react'

import './index.scss'

import { APPCONFIG } from '../../configs/common'
import { HOMECONFIG } from '../../configs/page'

interface HeaderProps {
  className?: string,
  elementRef?: RefObject<HTMLDivElement>,
  active: number,
  onChange: Function
}

interface HeaderState {}

class AppHeader extends Component<HeaderProps, HeaderState> {

  public static defaultProps = {
    className: '',
    elementRef: null,
    active: 0,
    onChange: () => {}
  }

  public state = {}

  handleChangeTab (index: number) {
    const { menuList } = HOMECONFIG
    this.props.onChange && this.props.onChange(menuList[index].jump, index)
  }

  public render () {
    let { className, elementRef, active } = this.props
    const { appTitle, appLogo } = APPCONFIG
    const { menuList } = HOMECONFIG
    return (
      <div className={`app-header flex-justify ${className}`} ref={elementRef}>
        <div className='header-container flex-align'>
          <div className='flex-one flex-align'>
            <img className='header-logo' src={appLogo} alt={appTitle} title={appTitle}/>
            <div className='header-title'>{appTitle}</div>
          </div>
          <div className='header-tab flex'>
            {
              menuList.map((item, index) => {
                return (
                  <div
                    className={`tab-item flex-align-justify ${active === index ? 'active' : ''}`}
                    key={index}
                    onClick={this.handleChangeTab.bind(this, index)}
                  >{item.label}</div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default AppHeader
