import ImageLogo from '../asserts/logo.png'
import ImageOfficialQrcode from '../asserts/home/contact/official.jpg'
import ImageContactQrcode from '../asserts/home/contact/contact.jpg'

const addressName = '武汉市洪山区高新大道776号华翔中心A2栋'
const addressLongitude = 114.506649
const addressLatitude = 30.488357
const addressMapKey = '6ZWBZ-XDY3J-HV7FB-FGJWU-AVRYE-VEBKF'

export const APPCONFIG = {
  appName: '金斗云',
  appTitle: '金斗云 · 珠宝新零售',
  appLogo: ImageLogo,
  address: {
    label: '地址',
    value: addressName,
    mapLink: `https://apis.map.qq.com/uri/v1/marker?marker=coord:${addressLatitude},${addressLongitude};title:${addressName};addr:${addressName}&referer=${addressMapKey}`
  },
  telphone: {
    label: '电话',
    value: '027-87896958'
  },
  email: {
    label: '邮箱',
    value: 'i@jdyyes.com'
  },
  serviceWechat: {
    label: '客服微信',
    value: '金斗云呀（jdyyes01）'
  },
  officialWechat: {
    label: '微信公众号',
    value: '金斗云智慧门店（jdysaas）'
  },
  officialQrcode: {
    label: '公众号',
    image: ImageOfficialQrcode
  },
  serviceQrcode: {
    label: '客服微信',
    image: ImageContactQrcode
  },
  copyright: 'Copyright © 2021. 宝谷之宝 All Rights Reserved.',
  recordNumber: {
    value: '鄂ICP备15005445号-5',
    link: 'http://beian.miit.gov.cn'
  }
}