import { Component } from 'react'

import './index.scss'
import 'swiper/components/effect-fade/effect-fade.scss'

import ImageBackground from '../../asserts/home/background.png'
import ImagePage11 from '../../asserts/home/page/page1-1.png'
import ImagePage12 from '../../asserts/home/page/page1-2.png'
import ImagePage2 from '../../asserts/home/page/page2.png'
import ImagePage31 from '../../asserts/home/page/page3-1.png'
import ImagePage32 from '../../asserts/home/page/page3-2.png'
import ImagePage41 from '../../asserts/home/page/page4-1.png'
import ImagePage42 from '../../asserts/home/page/page4-2.png'

import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Autoplay, EffectFade, Pagination])

interface BackgroundProps {
  onChange: Function
}

interface BackgroundState {
  activeIndex: number
}

class Background extends Component<BackgroundProps, BackgroundState> {

  public static defaultProps = {
    onChange: () => {}
  }

  public state = {
    activeIndex: 0
  }

  handleSlideChange (opts: any) {
    let activeIndex = opts.activeIndex - 1
    if (activeIndex === 4) {
      activeIndex = 0
    } else if (activeIndex === -1) {
      activeIndex = 3
    }
    this.setState({ activeIndex })
    this.props.onChange && this.props.onChange(activeIndex)
  }

  public render () {
    let { activeIndex } = this.state
    return (
      <div className='background-component'>
        <img className='background-picture' src={ImageBackground} alt='背景'/>
        <Swiper
          style={{ height: '100%', width: '100%' }}
          autoplay={{ disableOnInteraction: false }}
          nested
          loop
          effect='fade'
          pagination
          onSlideChange={this.handleSlideChange.bind(this)}
        >
          <SwiperSlide>
            <div className={`background-window ${activeIndex === 0 ? 'show' : ''}`}>
              <div className='window-container' style={{ backgroundImage: `url(${ImagePage11})` }}></div>
              <div className='window-border'></div>
              <div className='window-small' style={{ backgroundImage: `url(${ImagePage12})` }}></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`background-window ${activeIndex === 1 ? 'show' : ''}`}>
              <div className='window-container' style={{ backgroundImage: `url(${ImagePage2})` }}></div>
              <div className='window-border'></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`background-window ${activeIndex === 2 ? 'show' : ''}`}>
              <div className='window-container' style={{ backgroundImage: `url(${ImagePage31})` }}></div>
              <div className='window-border'></div>
              <div className='window-mini' style={{ backgroundImage: `url(${ImagePage32})` }}></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`background-phone-wrapper flex ${activeIndex === 3 ? 'show' : ''}`}>
              <div className='background-phone'>
                <div className='phone-container' style={{ backgroundImage: `url(${ImagePage41})` }}></div>
                <div className='phone-border'></div>
              </div>
              <div className='background-phone phone1'>
                <div className='phone-container' style={{ backgroundImage: `url(${ImagePage42})` }}></div>
                <div className='phone-border'></div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    )
  }
}

export default Background
