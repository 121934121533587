import Screen from './index'

class ScreenDev extends Screen {

  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      host: 'https://sapi.2bao.org',
      storageName: 'screen_dev',
    }
  }

}

export default ScreenDev
