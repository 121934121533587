import { Component } from 'react'

import './index.scss'

class NotFound extends Component<{}, {}> {
  public render () {
    return (
      <div className='app-page page-not-found'>
        <div className='not-found-container'>
          <div className='code'>404</div>
          <div className='tip'>很抱歉，您访问的页面不存在</div>
          <div className='tip'>SORRY,PAGE NOT FOUND</div>
        </div>
      </div>
    )
  }
}

export default NotFound