export function getBroswerAndVersion () {
  let userAgent: string = navigator.userAgent
  let info: string = ''
  let tempArray: any
  //判断浏览器版本
  let isOpera = userAgent.indexOf('Opera') > -1 //判断是否Opera浏览器
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera //判断是否IE浏览器
  let isEdge = userAgent.toLowerCase().indexOf('edge') > -1 && !isIE //判断是否IE的Edge浏览器
  let isIE11 = (userAgent.toLowerCase().indexOf('trident') > -1 && userAgent.indexOf('rv') > -1)
  if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {  
    tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else if (isIE) {  
    let version = ''
    let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    let fIEVersion = parseFloat(RegExp['$1'])
    switch (fIEVersion) {
      case 7:
        version = 'IE7'
        break
      case 8:
        version = 'IE8'
        break
      case 9:
        version = 'IE9'
        break
      case 10:
        version = 'IE10'
        break     
      default:
        version = '0'
        break
    } 
    info += version
  } else if (isEdge) {  
    info += 'Edge' 
  } else if (isIE11) {  
    info += 'IE11' 
  } else if (/[Cc]hrome\/\d+/.test(userAgent)) {  
    tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) {  
    tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent)
    info += tempArray[3] + tempArray[1]
  } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {  
    tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent)
    info += tempArray[1] + tempArray[2]
  } else {  
    info += 'unknown'
  }  
  return info.toLocaleLowerCase()
}

export function isLowVersionBrowser () {
  let browser: string = getBroswerAndVersion()
  let bol: boolean = false
  if (browser.indexOf('ie') >= 0 && parseFloat(browser.replace(/ie/g, '')) < 11) {
    bol = true
  } else if (browser.indexOf('chrome') >= 0 && parseFloat(browser.replace(/chrome/g, '')) < 29) {
    bol = true
  } else if (browser.indexOf('firefox') >= 0 && parseFloat(browser.replace(/firefox/g, '')) < 28) {
    bol = true
  } else if (browser.indexOf('safari') >= 0 && parseFloat(browser.replace(/safari/g, '')) < 6.1) {
    bol = true
  } else if (browser.indexOf('opera') >= 0 && parseFloat(browser.replace(/opera/g, '')) < 17) {
    bol = true
  }
  return bol
}

export function isMobileWechat (): boolean {
  const ua = navigator.userAgent.toLowerCase()
  const match = ua.match(/MicroMessenger/i)
  if (match && match.includes('micromessenger') && (ua.match(/(iPhone|iPod|iPad);?/i) || ua.match(/android/i))) return true
  return false
}

export function delay (time:number = 0) {
  time = time < 100 ? 0 : time
  return new Promise((resolve) => {
    if (!time) {
      resolve(true)
    } else {
      setTimeout(() => {
        resolve(true)
      }, time)
    }
  })
}

const toString = Object.prototype.toString

function isFunction (obj: any): boolean {
  return toString.call(obj) === '[object Function]'
}

export function eq (a: any, b: any, aStack: any, bStack: any): boolean {

  // === 结果为 true 的区别出 +0 和 -0
  if (a === b) return a !== 0 || 1 / a === 1 / b

  // typeof null 的结果为 object ，这里做判断，是为了让有 null 的情况尽早退出函数
  if (a == null || b == null) return false

  // 判断 NaN
  // eslint-disable-next-line no-self-compare
  if (a !== a) return b !== b

  // 判断参数 a 类型，如果是基本类型，在这里可以直接返回 false
  var type = typeof a
  if (type !== 'function' && type !== 'object' && typeof b != 'object') return false

  // 更复杂的对象使用 deepEq 函数进行深度比较
  return deepEq(a, b, aStack, bStack)
}

export function deepEq (a: any, b: any, aStack: any, bStack: any): boolean {

  // a 和 b 的内部属性 [[class]] 相同时 返回 true
  var className = toString.call(a)
  if (className !== toString.call(b)) return false

  switch (className) {
    case '[object RegExp]':
    case '[object String]':
      return '' + a === '' + b
    case '[object Number]':
      // eslint-disable-next-line no-self-compare
      if (+a !== +a) return +b !== +b
      return +a === 0 ? 1 / +a === 1 / b : +a === +b
    case '[object Date]':
    case '[object Boolean]':
      return +a === +b
  }

  var areArrays = className === '[object Array]'
  // 不是数组
  if (!areArrays) {
    // 过滤掉两个函数的情况
    if (typeof a != 'object' || typeof b != 'object') return false

    var aCtor = a.constructor,
        bCtor = b.constructor
    // aCtor 和 bCtor 必须都存在并且都不是 Object 构造函数的情况下，aCtor 不等于 bCtor， 那这两个对象就真的不相等啦
    if (aCtor !== bCtor && !(isFunction(aCtor) && aCtor instanceof aCtor && isFunction(bCtor) && bCtor instanceof bCtor) && ('constructor' in a && 'constructor' in b)) {
      return false
    }
  }


  aStack = aStack || []
  bStack = bStack || []
  var length = aStack.length

  // 检查是否有循环引用的部分
  while (length--) {
    if (aStack[length] === a) {
      return bStack[length] === b
    }
  }

  aStack.push(a)
  bStack.push(b)

  // 数组判断
  if (areArrays) {
    length = a.length
    if (length !== b.length) return false

    while (length--) {
      if (!eq(a[length], b[length], aStack, bStack)) return false
    }
  } else {
    var keys = Object.keys(a),
      key
    length = keys.length

    if (Object.keys(b).length !== length) return false
    while (length--) {
      key = keys[length]
      if (!(b.hasOwnProperty(key) && eq(a[key], b[key], aStack, bStack))) return false
    }
  }

  aStack.pop()
  bStack.pop()
  return true
}